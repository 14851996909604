import React from "react"
import Layout from '../components/layout'
import { graphql } from "gatsby"
import { Container } from 'react-bootstrap'
import { sliceConfig } from '../slice-configuration'
import { getBlockStyling, getDataAttributes } from '../utils/block'
import Header from '../components/blocks/Header'
import Illustrations from '../components/Illustrations'



export const query = graphql`

  fragment LinkAnchorFragment on PRISMIC_Link_anchor {
    _meta {
        id
        uid
        type
        lang
    }
  }

  query PageQuery($uid: String!, $lang: String!) {
    ... HeaderNavFragment
    ... FooterNavFragment
    ... SettingsFragment
    prismic {
      page(uid: $uid, lang: $lang) {
            translation_uid
            title
            meta_title
            meta_description
            meta_robots_index
            bg_color
            _meta {
              id
              uid
              lang
              type
            }
            parent {
              ... on PRISMIC_Page {
                _linkType
                _meta {
                  id
                  uid
                  lang
                  type
                }
              }
            }
            body {
              ... on PRISMIC_PageBodyContent {
                type
                primary {
                  bg_color
                  text_color
                  padding_top
                  padding_bottom
                  illustrations {
                    ... on PRISMIC_Illustration {
                      items {
                        illustration
                        width
                        top
                        right
                        bottom
                        left
                      }
                    }
                  }
                }
                fields {
                  column_direction
                  image_position
                  content_max_width
                  content
                  image
                  pretitle
                  title
                  link {
                    ... LinkFragment
                  }
                  link_anchor {
                    ... LinkAnchorFragment
                  }
                  link_text
                }
              }
              ... on PRISMIC_PageBodyAction_block {
                type
                primary {
                  entity {
                    ... on PRISMIC_Action_block {
                      content
                      image
                      image_column_width
                      link {
                        ... LinkFragment
                      }
                      link_anchor {
                        ... LinkAnchorFragment
                      }
                      link_text
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyHorizontal_line {
                type
                primary {
                  bg_color
                }
              }
              ... on PRISMIC_PageBodyColumns {
                type
                primary {
                  bg_color
                  text_color
                  section_anchor {
                    ... LinkAnchorFragment
                  }
                  padding_top
                  padding_bottom
                  text_align
                  pretitle
                  title
                }
                fields {
                  image
                  column_width
                  column_offset
                  content_max_width
                  layout
                  title
                  content
                  link {
                    ... LinkFragment
                  }
                  link_text
                }
              }
              ... on PRISMIC_PageBodyTab_panel {
                type
                primary {
                  bg_color
                }
                fields {
                  title
                  content
                  tab_name
                }
              }
              ... on PRISMIC_PageBodyPeople {
                type
                primary {
                  title
                  layout
                  bg_color
                  text_color
                }
                fields {
                  name
                  role
                  email
                  image
                  primary_color
                }
              }
              ... on PRISMIC_PageBodyForm {
                type
                primary {
                  title
                  bg_color
                  text_color
                  content
                  secondary_title
                  section_anchor {
                    ... LinkAnchorFragment
                  }
                  form {
                      ... on PRISMIC_Form {
                        _meta {
                          id
                          uid
                          type
                          lang
                        }
                        title
                        submit_button
                        success_message
                        fields {
                          label
                          placeholder
                          type
                          required
                          field_options
                          field_id
                        }
                      }
                    }
                  }
              }
              ... on PRISMIC_PageBodyAccordion {
                type
                primary {
                  title
                  bg_color
                  padding_top
                  padding_bottom
                }
                fields {
                  name
                  content
                }
              }
              ... on PRISMIC_PageBodyBoxes {
                type
                primary {
                  bg_color
                  text_color
                  padding_top
                  padding_bottom
                }
                fields {
                  image
                  image_caption
                  primary_color
                  secondary_color
                  title
                  content
                }
              }
              ... on PRISMIC_PageBodyContent_list {
                type
                primary {
                  bg_color
                }
                fields {
                  title
                  content
                  primary_color
                }
              }
              ... on PRISMIC_PageBodyTestimonials {
                type
                primary {
                  bg_color
                }
                fields {
                  quote
                  author_image
                  author_name
                  author_role
                }
              }
              ... on PRISMIC_PageBodyGallery {
                type
                primary {
                  bg_color
                  padding_top
                  padding_bottom
                  gallery {
                    ... on PRISMIC_Gallery {
                      title
                      photos {
                        image
                        column_width
                      }
                    }
                  }
                  illustrations {
                    ... on PRISMIC_Illustration {
                      items {
                        illustration
                        width
                        top
                        right
                        bottom
                        left
                        transform_x
                        transform_y
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyNavigation {
                type
                primary {
                  navigation {
                    ... on PRISMIC_Navigation {
                      ... NavigationFragment
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyMedium_articles {
                type
                primary {
                  title
                  bg_color
                }
              }
              ... on PRISMIC_PageBodyArticles {
                type
                primary {
                  title
                  bg_color
                }
              }
              ... on PRISMIC_PageBodyLogos {
                type
                primary {
                  title
                  bg_color
                  collection {
                    ... on PRISMIC_Logo_collection {
                      logos {
                        image
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
`


const Page = ({ data, prismic }) => {

    let doc = data.prismic.page
    if ( !doc ) return null

    let layoutParams = { 
      doc: doc, 
      headerNav: data.header.navigation,
      secondaryNav: data.secondaryNav.navigation,
      footerNav: data.footer.navigation, 
      settings: data.settings.global 
    }

    let layout = doc.body.map( (slice) => {
 
        let Component = sliceConfig[ slice.type ]
        if ( ! Component ) return <></>

        let block = <></>
        let attrs = getDataAttributes(slice.primary)
        if ( ! Component.repeatable ) {
          block = <Component.component prismic={prismic} primary={slice.primary} items={slice.fields}></Component.component>
        } else {
          block = slice.fields.map( item => <Component.component primary={slice.primary} item={item}></Component.component> )
        }

        if ( Component.rowWrap ) {
          block = <div class="row">{block}</div>
        }

        let header = Component.header !== false ? <Header slice={slice}></Header> : <></>
        let illustrations = slice.primary && slice.primary.illustrations ? <Illustrations slice={slice}></Illustrations> : <></>

        return Component.wrap ? (
            <section className="o-section" id={slice?.primary?.section_anchor?._meta?.uid} data-slice={slice.type} style={getBlockStyling(slice.primary)} {...attrs}>
              {illustrations}
              <Container>
                {header}
                {block}
              </Container>
            </section>
        ) : <>{block}</>
    })


    return <Layout {...layoutParams}>{layout}</Layout>

}

export default Page