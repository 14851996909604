import React from 'react'
import { getColumnClass } from '../../../utils/columns'

import './index.scss'

export default ({ primary }) => {

    return (
        <div class="c-photo-gallery">
            <div class="row">
                {primary.gallery.photos.map(item => {
                    return (
                        <div class={getColumnClass(item.column_width) + ' gallery-column'}>
                            <img src={item.image.url} alt={item.image.alt} className="img-fluid" />
                        </div>
                    )
                })}
            </div>
        </div>
    )

}