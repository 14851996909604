import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'
import Header from '../Header'

import './index.scss'


export default ({ primary, items = [] }) => {

    if ( primary.layout === 'Horizontal' ) {
        return (
            <div className="row align-items-center">
                <div className="col-md-4">
                    <RichText render={primary.title} linkResolver={linkResolver} />
                </div>
                <div className="col-md-8">
                    <div className="row">
                        {items.map(item => <div className="col-md-3"><Person {...item}></Person></div>)}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header slice={{ primary: primary }}></Header>
            <div className="row">
                {items.map(item => <div className="col-md-4 col-lg-3"><Person {...item}></Person></div>)}
            </div>
        </>
    )

}

const Person = (props) => {

    return (
        <div className="o-person" style={{ '--primary-color': props.primary_color }}>
            {props.image ? <img src={props.image.url} alt={props.image.alt} className="img-fluid" /> : <></>}
            <div className="meta">
                <div className="author-name">{props.name}</div>
                <div className="author-role">{props.role}</div>
                {props.email ? <div className="author-email"><a href={`mailto:${props.email}`}>{props.email}</a></div> : <></>}
            </div>
        </div>
    )

}
