import React from 'react'
import { RichText } from 'prismic-reactjs'
import Slider from 'react-slick'

import './index.scss'

export default ({ items = [] }) => {

    let settings = {
        dots: true,
        arrows: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <Slider {...settings}>
            {items.map(item => (
                <div>
                    <div class="o-testimonial">
                        <div class="content-holder">
                            <div className="testimonial-copy">
                                {RichText.asText(item.quote)}
                            </div>
                        </div>
                        <div class="image-holder">
                            <img src={item.author_image.url} alt={item.author_image} className="img-fluid author-image"></img>
                            <div class="author-details">
                                <div className="author-name">{item.author_name}</div>
                                <div className="author-role">{item.author_role}</div>
                                <div className="author-company">{item.author_company}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    )
}