import React from 'react'
import { RichText } from 'prismic-reactjs'
import Link from '../../link'
import { getColumnClass } from '../../../utils/columns'
import { linkResolver } from '../../../utils/linkResolver'

import './index.scss'

export default ({ primary }) => {

    let link = primary.entity.link ? 
        <Link 
            to={primary.entity.link} 
            anchor={primary.entity.link_anchor}
            className="o-btn u-btn-primary">{primary.entity.link_text}</Link> 
    : <></>

    return (
        <>
            <div class="o-action-block">
                {primary.entity.image ? <div class={getColumnClass(primary.entity.image_column_width || '1/4')}><img src={primary.entity.image.url} alt={primary.entity.image.alt} className="img-fluid" /></div> : <></>}
                <div class="o-content h2"><RichText render={primary.entity.content} linkResolver={linkResolver} /></div>
                {link}
            </div>
        </>
    )

}