import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'
import './index.scss'


export default ({ primary, items }) => {

    const [logos, setLogos] = useState([])


    useEffect(() => {
        const shuffled = primary.collection ? primary.collection.logos.sort(() => 0.5 - Math.random()) : []
        let selected = shuffled.slice(0, 6)
        setLogos(selected)
    }, [])

    return (
        <>
            <RichText render={primary.title} linkResolver={linkResolver} />
            <div class="o-logo-collection">
                {logos.map( item => {
                    return (
                        <div class="logo">
                            {item.image ? <img src={item.image.url} alt={item.image.alt} class="img-fluid" /> : <></>}
                        </div>
                    )
                })}
            </div>
        </>
    )

}