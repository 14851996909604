import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'

import './index.scss'

export default ({ primary }) => {

    
    return (
        <div class="o-form">
            <div class="row">
                <div class="col-md-5">
                    <div class="o-secondary-title"><RichText render={primary.secondary_title} linkResolver={linkResolver} /></div>
                    <div class="o-content">
                        <RichText render={primary.content} linkResolver={linkResolver} />
                    </div>
                </div>
                <div class="col-md-5 offset-md-1">
                    <RichText render={primary.title} linkResolver={linkResolver} />
                    <CustomForm {...primary.form}></CustomForm>
                </div>
            </div>
        </div>
    )

}

const CustomForm = (props) => {

    const [fieldState, setFieldState] = useState({})
    const [errors, setErrorsState] = useState([])
    const [submitted, setSubmitted] = useState(false)


    const handleDataLayer = () => {
        if ( typeof window.dataLayer !== "undefined" ) {
            window.dataLayer.push({
                event: "form-submission",
                formName: props._meta.uid,
                pageName: window.location.pathname
            })
        }
    }


    const handleSubmit = async(e) => {

        e.preventDefault()

        let response = await fetch('/.netlify/functions/form-submission', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                uid: props?._meta.uid,
                lang: props?._meta.lang,
                ...fieldState
            })
        })

        if ( response.status === 422 ) {
            let json = await response.json()
            setErrorsState(json)
        }

        if ( response.status === 200 ) {
            setSubmitted(true)
            handleDataLayer()
        }
    }


    const handleChange = (e) => {
        const newFieldState = fieldState
        newFieldState[e.target.name] = e.target.value
        setFieldState( newFieldState );
    }


    const fieldPlaceholder = (field) => {
        return field.placeholder + (field.required === 'Yes' ? '*' : '')
    }


    if ( submitted ) {
        return <div>{RichText.render(props.success_message)}</div>
    }


    return (

        <form data-netlify="true" onSubmit={handleSubmit}>

            { errors.length ? (
                <div class="form-errors">
                    <ul>
                        {errors.map(error => (
                            error.conditions.map(condition => (
                                <li>{condition.message}</li>
                            ))
                        ))}
                    </ul>
                </div>
            ): <></>}

            <div className="form-fields">
                
                {props.fields.map((field, index) => {

                    let control = <></>
                    if ( field.type === 'text' ) {
                        control = <input className="form-control" onBlur={handleChange} name={field.field_id} placeholder={fieldPlaceholder(field)} />
                    } else if ( field.type === 'textarea' ) {
                        control = <textarea rows="4" className="form-control" onBlur={handleChange} name={field.field_id} placeholder={fieldPlaceholder(field)}></textarea>
                    } else if ( field.type === 'select' && field.field_options ) {
                        let fieldOptions = field.field_options.split("\n")
                        control = (
                            <select class="form-control" name={field.field_id} onBlur={handleChange}>
                                <option value="">{fieldPlaceholder(field)}</option>
                                {fieldOptions.map(option => {
                                    let o = option.split(':')
                                    return <option value={o[0]}>{o[1] ? o[1] : o[0]}</option>
                                })}
                            </select>
                        )
                    }

                    return (
                        <div class="form-group">
                            <label>{field.label}</label>
                            {control}
                        </div>
                    )

                })}
            </div>


            <button type="submit" className="o-btn u-btn-primary">{props.submit_button}</button>
        </form>
    )
}
