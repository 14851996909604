import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'

const PreTitle = ({children}) => {
    return <div className="o-pre-title">{children}</div>
}

const richTextEmpty = (richtext) => {
    return !!! RichText.asText(richtext)
}

export default ({slice}) => {

    if ( ! slice.primary ) return <></>

    if ( ! richTextEmpty(slice.primary.title) || ! richTextEmpty(slice.primary.content) ) {
        return (
            <div className="o-section-header">
                <RichText render={slice.primary.pretitle} linkResolver={linkResolver} Component={PreTitle} />
                <RichText render={slice.primary.title} linkResolver={linkResolver} />
            </div>
        )
    }

    return <></>

}
    