import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'

import './index.scss'

export default (props) => {

    const [activeKey, setActiveKey] = useState(0)

    let tabNames = props.items.map(item => item.tab_name)

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
            <div class="row">
                <div class="col-md-4">
                    <div class="tab-nav">
                        {tabNames.map((tab, index) => <Nav.Item><Nav.Link as="span" eventKey={index} className={[index == activeKey ? 'active' : '']} onSelect={(selectedKey) => setActiveKey(selectedKey)}>{tab}</Nav.Link></Nav.Item>)}
                    </div>
                </div>
                <div class="col-md-8">
                    {props.items.map((item, index) => {
                        return (
                            <Tab.Content>
                                <Tab.Pane eventKey={index}>
                                    <RichText render={item.title} linkResolver={linkResolver} />
                                    <RichText render={item.content} linkResolver={linkResolver} />
                                </Tab.Pane>
                            </Tab.Content>
                        )
                    })}
                </div>
            </div>
        </Tab.Container>
    )

}