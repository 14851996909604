import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'
import './index.scss'


export default ({ primary, items }) => {

    return (
        <>
            <div class="c-content-list">
                <div class="row">
                    {items && items.map( item => {
                        return (
                            <div class="col-md-6">
                                <div class="row list-item" style={{ '--primary-color': item.primary_color }}>
                                    <div class="col-md-4">
                                        <h4><RichText render={item.title} linkResolver={linkResolver} /></h4>
                                    </div>
                                    <div class="col-md-8">
                                        <RichText render={item.content} linkResolver={linkResolver} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}