import React from 'react'

import './index.scss'

export default ({ primary }) => {

    return (
        <hr class="horizontal-line"/>
    )

}