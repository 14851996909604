import React from 'react'

import './index.scss'

export default ({slice}) => {

    return (
        <div className="illustrations" aria-hidden="true">
            {slice.primary && slice.primary.illustrations.items.map(item => {

                if ( !item.illustration ) return <></>

                let transformX = `translateX(${item.transform_x})`
                let transformY = `translateY(${item.transform_y})`
                let transform = [transformX, transformY].join(' ')

                    let styles = { 
                        width: item.width, 
                        top: item.top, 
                        right: item.right, 
                        bottom: item.bottom, 
                        left: item.left,
                        transform: transform
                    }

                return (
                    <div className="illustration" style={styles}>
                        <img src={item.illustration.url} className="img-fluid" alt=""></img>
                    </div>
                )

            })}
        </div>
    )
}