import { graphql, Link } from 'gatsby'
import React, { useEffect } from 'react'
import { RichText, Link as PrismicLink } from 'prismic-reactjs'

import moment from 'moment'
import Image from '../../image'
import { linkResolver } from '../../../utils/linkResolver'

import './index.scss';

export default ({ primary, prismic }) => {

    const [articles, setArticles] = React.useState([])

    const articlesQuery = `
        query ArticlesQuery(
            $lang: String = "is",
            $first: Int = 6, 
        ) {
            prismic {
                allArticles(
                    sortBy: date_DESC
                    lang: $lang
                    first: $first
                ) {
                    totalCount
                    edges {
                        node {
                            _meta {
                                id
                                uid
                                type
                                lang
                            }
                            title
                            summary
                            image
                            person {
                                ... on Person {
                                    name
                                    role
                                    image
                                }
                            }
                        }
                    }
                }
            }
        }
    `

    useEffect(() => {

        let variables = {
            lang: 'is',
            first: 3,
        }

        // this function automatically passes the uid and id of the current page to the query
        // dont use these variables in the query if not needed
        prismic.load({
            variables: variables,
            query: articlesQuery, // (optional)
            //fragments: [fragment], // (optional)
        }).then(res => {
            setArticles(res.data.allArticles.edges)
        })
    
    }, [])


    let layout = articles.map((item, index) => (
        <div className="col-md-4" key={index}>
            <Link to={PrismicLink.url({ link_type: 'Document', ...item.node._meta }, linkResolver)} class="o-article">
                <ArticleBox {...item.node} />
            </Link>
        </div>
    ))

    return (
        <div className="articles">
            <div className="articles-header"><RichText render={primary.title} linkResolver={linkResolver} /></div>
            <div className="row">{layout}</div>
            {/*<div class="o-footer">
                <a href="/blog">Read more articles</a>
            </div>*/}
        </div>
    )


}

const ArticleBox = (props) => {

    let {
        title,
        summary,
        image,
        person,
        date,
    } = props

    return (
        <>
            {image && <div class="image-holder">
                <Image image={image} />
            </div>}
            <div class="text-content">

                <div>
                    <h3 className="post-title">{RichText.asText(title)}</h3>
                    <div className="o-content">
                        {RichText.render(summary)}
                    </div>
                    
                    <span className="read-more">Read more</span>
                </div>

                {person && 
                <div className="post-author">
                    <img width="64" height="64" src={person.image.url} className="author-image"></img>
                    <div className="author-meta">
                        <div className="author-name">{person.name}</div>
                        <div className="post-date">{moment(date).format('DD MMM YYYY')}</div>
                    </div>
                </div>}
                
            </div>
        </>
    )

}


export const pageFragment = graphql`
    fragment PageArticlesBlockFragment on PRISMIC_PageBodyArticles {
        type
        primary {
            title
            bg_color
        }
    }
`


    
