import React from 'react'
import { RichText } from 'prismic-reactjs'
import { getColumnClass } from '../../../utils/columns'
import { linkResolver } from '../../../utils/linkResolver'

import './index.scss'

export default ({ primary, item }) => {
    
    return (
        <div className={ getColumnClass( item.column_width ) }>
            <div className="o-column" data-layout={item.layout.toLowerCase()} style={{ '--max-width': item.content_max_width }}>
                {item.image ? <img src={item.image.url} alt={item.image.alt} className="img-fluid" /> : <></>}
                <div className="content-holder">
                    <RichText render={item.title} linkResolver={linkResolver} />
                    <div className="o-content">
                        <RichText render={item.content} linkResolver={linkResolver} />
                    </div>
                </div>
            </div>
        </div>
    )

}