import ContentBlock from './components/page/ContentBlock'
import Column from './components/page/Column'
import LogoCollection from './components/page/LogoCollection'
import ContentList from './components/page/ContentList'
import Navigation from './components/blocks/Navigation'
import Testimonials from './components/blocks/Testimonials'
import Form from './components/blocks/Form'
import Accordion from './components/blocks/Accordion'
import TabPanel from './components/blocks/TabPanel'
import People from './components/blocks/People'
import Gallery from './components/blocks/Gallery'
import HorizontalLine from './components/blocks/HorizontalLine'
import ActionBlock from './components/blocks/ActionBlock'
import MediumArticles from './components/blocks/MediumArticles'
import Articles from './components/blocks/Articles'

export const sliceConfig = {
    content: {
        repeatable: true,
        component: ContentBlock,
        wrap: true
    },
    columns: {
        repeatable: true,
        component: Column,
        wrap: true,
        rowWrap: true
    },
    logos: {
        repeatable: false,
        component: LogoCollection,
        wrap: true,
        header: false
    },
    content_list: {
        repeatable: false,
        component: ContentList,
        wrap: true
    },
    navigation: {
        repeatable: false,
        component: Navigation,
        wrap: true
    },
    testimonials: {
        repeatable: false,
        component: Testimonials,
        wrap: true
    },
    form: {
        repeatable: false,
        component: Form,
        wrap: true,
        header: false
    },
    accordion: {
        repeatable: false,
        component: Accordion,
        wrap: true,
        header: false
    },
    tab_panel: {
        repeatable: false,
        component: TabPanel,
        wrap: true
    },
    people: {
        repeatable: false,
        component: People,
        wrap: true,
        header: false
    },
    gallery: {
        repeatable: false,
        component: Gallery,
        wrap: true
    },
    horizontal_line: {
        repeatable: false,
        component: HorizontalLine,
        wrap: true
    },
    action_block: {
        repeatable: false,
        component: ActionBlock,
        wrap: true
    },
    medium_articles: {
        repeatable: false,
        component: MediumArticles,
        wrap: true,
        header: false
    },
    articles: {
        repeatable: false,
        component: Articles,
        wrap: true,
        header: false
    }
}