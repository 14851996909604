import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'
import { RichText } from 'prismic-reactjs'
import { RichTextLink } from '../../link'

import './index.scss'

export default ({ primary, items }) => {

    return (
        <>
            <div class="o-accordion-container">
                {RichText.render(primary.title)}
                <Accordion>
                    {items.map((item, index) => {
                        return (
                            <Card>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey={index + 1}>
                                 
                                        {item.name}
                                        <i aria-hidden="true" class="question icon"></i>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={index + 1}>
                                    <Card.Body><RichText render={item.content} serializeHyperlink={RichTextLink} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )
                    })}
                </Accordion>
            </div>
        </>
    )

}