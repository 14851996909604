import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Slider from 'react-slick'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'


import './index.scss'

export default (props) => {

    const [posts, setPosts] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let request = await fetch('/.netlify/functions/medium', {
                method: 'POST'
            })
            let data = await request.text()
            data = JSON.parse(data)
            setPosts(Object.values(data.payload.references.Post))
            setUsers(Object.values(data.payload.references.User))

        }
        fetchData()
    }, [])

    let settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <div className="medium-posts">
            <div className="articles-header">
                <RichText render={props.primary.title} linkResolver={linkResolver} />
            </div>
            <Slider {...settings}>
                {posts.map(post => {
                    let image = post.virtuals.previewImage
                    let author = users.find(user => user.userId === post.creatorId)
                    return (
                        <>
                            <div className="row">
                                <div className="col-md-7">
                                    <a href={`https://medium.com/meniga-iceland/${post.id}`} target="_blank" rel="noreferrer">
                                        <img src={`https://cdn-images-1.medium.com/fit/t/${image.originalWidth}/${image.originalHeight}/${image.imageId}`} alt="" className="img-fluid"></img>
                                    </a>
                                </div>
                                <div className="col-md-5">
                                    <h3 className="post-title"><a href={`https://medium.com/meniga-iceland/${post.id}`} target="_blank" rel="noreferrer">{post.title}</a></h3>
                                    <div className="o-content">
                                        {post.content.subtitle}
                                    </div>
                                    {author ? 
                                        <div className="post-author">
                                            <img src={`https://cdn-images-1.medium.com/fit/t/200/200/${author.imageId}`} alt="" className="author-image img-fluid"></img>
                                            <div className="author-meta">
                                                <div className="author-name">{author.name}</div>
                                                <div className="post-date">{moment(post.firstPublishedAt).format('MMM d')}</div>
                                            </div>
                                        </div>
                                    : <></>}
                                </div>
                            </div>
                        </>
                    )
                })}
            </Slider>
        </div>
    )
    

}